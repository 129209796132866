<template>
  <div>
    <v-card elevation="0" class="pa-3 rounded-3 card-box">
      <v-card-title class="d-flex align-center justify-center">
        <div class="layout column align-center">
          <v-img
            src="@/assets/logo.png"
            alt="Bina logo"
            width="120"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <h1 class="mt-4 primary--text text--darken-1">
          {{ $t('login.forgotPassword') }}
        </h1>
        <p class="mt-2 caption">
          {{ $t('login.pleaseForgotPassword') }}
        </p>
      </v-card-text>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="model.email"
            :disabled="disabledFields"
            name="email"
            append-icon="mdi-account"
            :label="$t('login.email')"
            type="email"
            :rules="emailValid"
            required
            autocomplete="username"
          />
          <div class="mt-8">
            <localization />
            <v-btn
              class="text-capitalize"
              color="primary"
              :disabled="!valid"
              :loading="loading"
              @click="forgotPassword"
              width="80%"
            >
              {{ $t('login.send') }}
              <template v-slot:loader>
                <span>{{ $t('common.loading') }}</span>
              </template>
            </v-btn>
          </div>
          <!-- <div class="text-center mt-4" v-if="disabledFields">
            <h5 class="green--text">
              {{$t('login.checkYourInbox')}}
            </h5>
          </div> -->
          <div class="text-center mt-4">
            {{ $t('login.rememberPassword') }}
            <a
              class="accent--text text--lighten-2"
              @click="$emit('update:loginPage', true)"
            >
              {{ $t('singup.signInstead') }}
            </a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Localization from '@/views/widget/AppLocalization.vue';
import { mapActions } from "vuex";

export default {
  props: ["loginPage"],
  name: 'ForgotPassword',
  components: { Localization },
  data: () => ({
    loading: false,
    valid: false,
    model: {
      email: '',
    },
    disabledFields: false,
  }),
  computed: {
    emailValid() {
      return [
        v => !!v || this.$t('formRules.emailRequired') ,
        v => /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage')
      ];
    },
  },
  methods: {
    ...mapActions({
      forgotByEmail: "ForgotByEmail",
    }),
    async forgotPassword() {
      this.loading = true;
      const params = {
        email: this.model.email,
      };
      this.forgotByEmail(params)
      .then(() => {
        // this.$router.push({ path: '/' });
        this.loading = false;
        this.disabledFields = true;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
    },
  },
};
</script>
<style scoped>
.card-box {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
  z-index: 1;
}
.auth-mask-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.auth-tree,
.auth-tree-3 {
  position: absolute;
}
.auth-tree {
  bottom: 0;
  left: 0;
}
.auth-tree-3 {
  bottom: 0;
  right: 0;
}
</style>
